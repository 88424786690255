.mde-preview-content {
  height: 100%;
  overflow-y: scroll;
}

.react-mde {
  border: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mde-textarea-wrapper {
  height: 100%;
}

.mde-text {
  height: 100%;
  overflow-y: auto;
}
