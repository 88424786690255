.app {
  height: 100%;
}

.treeRoot {
  list-style-type: none;
  padding: 1em;
}

.draggingSource {
  opacity: 0.3;
  background-color: green;
}

.dropTarget {
  background-color: #87b3ff;
}

.listItem {
  list-style-type: none;
}
